<template>
  <div class="group-contact">
    <div class="group-contact-left"></div>
    <div class="group-contact-right">
      <div class="form">
        <span class="form-title">PQR</span>
        <span class="form-description">Para nosotros su opinión es muy importante. Cuéntenos cómo ha sido su experiencia, sus ideas, preguntas o sugerencia. Gracias por su tiempo.</span>
        <div class="form-inputs">
          <input type="text" name="name" v-model="form.name" class="form-inputs-text" v-validate="'required|min:3'" placeholder="Nombre" :class="{error: errors.first('name')}">
          <input type="text" name="email" v-model="form.email" class="form-inputs-text" v-validate="'required|email'" placeholder="E-mail" :class="{error: errors.first('email')}">
        </div>
        <div class="form-inputs">
          <input type="text" name="phone" v-model="form.phone" class="form-inputs-text" v-validate="'required|min:7|max:14'" placeholder="Teléfono" :class="{error: errors.first('phone')}">
          <input type="text" name="subject" v-model="form.subject" class="form-inputs-text" v-validate="'required|min:5'" placeholder="Asunto" :class="{error: errors.first('subject')}">
        </div>
        <div class="form-inputs">
          <textarea  name="message" v-model="form.message" class="form-inputs-textarea" v-validate="'required|min:12'" placeholder="Mensaje" :class="{error: errors.first('message')}"></textarea>
        </div>
        <span class="error-text" v-if="errors.items.length > 0">* Campos obligatorios</span>
        <div class="form-terms">
          <input type="checkbox" v-model="terms" class="form-terms-check">
          <span class="checkbox-container"></span>
          <span class="form-terms-text">Acepto el uso de mis datos para envío de información, para más información políticas de privacidad. <span class="politic">Políticas de privacidad</span></span>
        </div>
      </div>
      <button class="group-contact-right-btn" v-if="terms && !loading" @click="sendPqr()">
        <span>Enviar</span>
      </button>
      <span class="group-contact-right-btn" v-if="terms && loading" style="cursor: not-allowed;">
        <i class="fa fa-spinner fa-spin" style="color: white;"></i>
      </span>
    </div>
  </div>
</template>
<script>
import serviceMail from '@/services/serviceMail.js';
export default {
  data () {
    return {
      loading: false,
      terms: true,
      form: {
        name: null,
        email: null,
        subject: null,
        phone: null,
        message: null
      }
    };
  },
  methods: {
    sendPqr () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          serviceMail.post('/contact', this.form).then(({ data }) => {
            this.$swal({
              title: '¡Éxito!',
              text: data.message || 'Tu operación fue exitosa',
              icon: 'success',
              confirmButtonText: 'OK'
            });
            this.loading = false;
            this.form = {
              name: null,
              email: null,
              subject: null,
              phone: null,
              message: null
            };
          }).catch(err => {
            console.error(err);
            this.loading = false;
            this.$swal({
              title: '¡Error!',
              text: 'Hubo un problema al enviar el formulario.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          });
        } else {
          const errorMessages = [];
          for (const error of Object.values(this.errors.items)) {
            let msg = error.msg;
            switch (error.field) {
              case 'email':
                msg = 'El correo electrónico es obligatorio y debe ser un correo electrónico válido';
                break;
              case 'name':
                msg = 'El nombre es obligatorio y debe tener un mínimo de 3 caracteres';
                break;
              case 'phone':
                msg = 'El teléfono es obligatorio y debe tener entre 7 y 14 caracteres';
                break;
              case 'subject':
                msg = 'El asunto es obligatorio y debe tener un mínimo de 5 caracteres';
                break;
              case 'message':
                msg = 'El mensaje es obligatorio y debe tener un mínimo de 12 caracteres';
                break;
            }
            errorMessages.push(msg);
          }
          const errorList = errorMessages.map(error => `<li style="color: red;">${error}</li>`).join('');
          this.$swal({
            title: '¡Error!',
            html: `Debes ingresar los datos de forma correcta: <br><br> <ul>${errorList}</ul>`,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>
<style lang="scss">
.group-container {
  height: auto;
}
.group-contact {
  display: flex;
  align-items: flex-start;
  gap: 108px;
  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    gap: unset;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    gap: 25px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    gap: 30px;
  }
  @media screen and (max-width: 767px) {
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }
  &-left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 643px;
    width: 604px;
    background: url('/images/new-dlk/contactenos_pqr.png');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (min-width: 1280px) and (max-width: 1439px) {
      width: 500px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      width: 463px;
      height: 539px;
      flex-shrink: 0;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 316px;
      height: 751px;
      flex-shrink: 0;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      height: 157px;
      flex-shrink: 0;
    }
  }
  &-right {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 64px;
    @media screen and (min-width: 1280px) and (max-width: 1439px) {
      margin: 64px auto 0 auto;
    }
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      margin-top: 30px;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin-top: 40px;
    }
    @media screen and (max-width: 767px) {
      margin-top: unset;
      width: 100%;
      height: 100%;
    }
    .form {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 646px;
      height: 434px;
      flex-shrink: 0;
      @media screen and (min-width: 1280px) and (max-width: 1439px) {
        width: 620px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        width: 510px;
        height: 366px;
        flex-shrink: 0;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 402px;
        height: 485px;
        flex-shrink: 0;
      }
      @media screen and (max-width: 767px) {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        align-items: center;
      }
      .error-text {
        color: red;
        font-weight: 500;
        margin-bottom: 20px;
      }
      &-title {
        width: 100%;
        color: #000;
        text-align: left;
        font-family: 'Open Sans';
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          width: 510px;
          font-size: 32px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          width: 402px;
          font-size: 32px;
        }
        @media screen and (max-width: 767px) {
          width: 382px;
          font-size: 32px;
        }
      }
      &-description {
        color: #000;
        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 35px;
        width: 587px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          width: 510px;
          font-size: 14px;
          margin-bottom: 20px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          width: 402px;
          margin-bottom: 20px;
          font-size: 14px;
        }
        @media screen and (max-width: 767px) {
          width: 382px;
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
      &-inputs {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          width: 510px;
          margin-bottom: 10px;
          gap: 10px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          flex-direction: column;
          width: 382px;
          margin-bottom: 10px;
          gap: 10px;
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
          width: 382px;
          margin-bottom: 10px;
          gap: 10px;
        }
        .error {
          border: 1px solid red;
          &::placeholder {
            color: red;
          }
        }
        &-text {
          width: 300px;
          height: 40px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #E2E2E2;
          background: #FFF;
          padding-left: 20px;
          padding-bottom: 5px;
          @media screen and (min-width: 1024px) and (max-width: 1279px) {
            width: 250px;
          }
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            width: 100%;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          &::placeholder {
            color: #626262;
            font-family: 'Open Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        &-textarea {
          width: 620px;
          height: 100px;
          max-height: 100px;
          min-height: 100px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #E2E2E2;
          background: #FFF;
          padding-left: 20px;
          padding-top: 10px;
          @media screen and (min-width: 1024px) and (max-width: 1279px) {
            width: 510px;
          }
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            width: 382px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          &::placeholder {
            color: #626262;
            font-family: 'Open Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      &-terms {
        width: 620px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        position: relative;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          width: 510px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          width: 382px;
          gap: 10px;
        }
        @media screen and (max-width: 767px) {
          width: 382px;
          gap: 10px;
        }
        .checkbox-container {
          display: inline-block;
          position: relative;
          width: 20px;
          height: 20px;
          background-color: #000;
          border-radius: 3px;
          z-index: 0;
          &::after {
            content: "";
            position: absolute;
            display: none;
            left: 50%;
            top: 40%;
            width: 8px;
            height: 13px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
        &-check {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          border-radius: 3px;
          border: 1px solid #000000;
          background: #FFF;
          cursor: pointer;
          appearance: none;
          display: inline-block;
          opacity: 0;
          z-index: 1;
          left: 0px;
          position: absolute;
          &:checked + .checkbox-container::after {
            display: block;
          }
        }
        &-text {
          width: 584px;
          color: #626262;
          font-family: 'Open Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media screen and (min-width: 1024px) and (max-width: 1279px) {
            font-size: 12px;
            width: 481px;
          }
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            font-size: 12px;
            width: 357px;
          }
          @media screen and (max-width: 767px) {
            font-size: 12px;
            width: 357px;
          }
          .politic {
            color: #626262;
            font-family: 'Open Sans';
            font-size: 14px;
            font-style: italic;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline;
            cursor: pointer;
            @media screen and (max-width: 1279px) {
              font-size: 12px;
            }
          }
        }
      }
    }
    &-btn {
      width: 150px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #000;
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        margin-top: 30px;
      }
      @media screen and (min-width: 767px) and (max-width: 1023px) {
        margin-top: 30px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 30px;
        width: 100%;
        margin-bottom: 50px;
      }
      span {
        color: #FFF;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
</style>
